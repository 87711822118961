import React from "react";

export const Explore = () => {
	return (
		<article className="explore">
			<h1>Explore</h1>
			<div>
				<p>This is a sentence with words and thhe text keeps expanding to the left let's see ohw ti goesThis is a sentence with words and thhe text keeps expanding to the left let's see ohw ti goesThis is a sentence with words and thhe text keeps expanding to the left let's see ohw ti goesThis is a sentence with words and thhe text keeps expanding to the left let's see ohw ti goesThis is a sentence with words and thhe text keeps expanding to the left let's see ohw ti goesThis is a sentence with words and thhe text keeps expanding to the left let's see ohw ti goesThis is a sentence with words and thhe text keeps expanding to the left let's see ohw ti goesThis is a sentence with words and thhe text keeps expanding to the left let's see ohw ti goes</p>
			</div>

		</article>
		);
};